import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login.vue'),
        meta: {
            login: false
        },
    },

    {
        path: '/',
        name: 'layout',
        component: () => import('@/components/dashboard.vue'),
        meta: {
            login: true,
            crumb: 'Trang chủ',
        },
        children: [
            {
                path: 'info',
                name: 'info',
                component: () => import('@/views/admin/index.vue'),
                meta: {
                    login: true,
                    crumb: 'info'
                },
            },
            {
                path: 'customer',
                name: 'customer',
                component: () => import('@/views/customer/index.vue'),
                meta: {
                    login: true,
                    crumb: 'Danh sách khách hàng'
                },
            },
            {
                path: 'customer/add',
                name: 'customer-add',
                component: () => import('@/views/customer/add.vue'),
                meta: {
                    login: true,
                    crumb: 'Thêm khách hàng'
                },
            },
            {
                path: 'customer/:id',
                name: 'customer-show',
                component: () => import('@/views/customer/show.vue'),
                meta: {
                    login: true,
                    crumb: 'Thông tin khách hàng'
                },
            },
        ]
    },

]

const router = createRouter({
    history: createWebHistory(process.env.VUE_APP_BASE_URL),
    routes
})

export default router
