<template>
    <router-view/>
</template>

<style lang="scss">
input, button {
    &:focus {
        box-shadow: none !important;
    }
}
.fs-18px {
    font-size: 18px !important;
}
.ivu-card-shadow {
    box-shadow: 0 0 30px 0 rgba(82, 63, 105, 0.05) !important;
    border-radius: 8px !important;
}
.title-card {
    font-weight: 600 !important;
}
.filepond--credits {
        display: none !important;
    }

</style>
