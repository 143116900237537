import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ViewUIPlus from 'view-ui-plus'
import 'view-ui-plus/dist/styles/viewuiplus.css'
import locale from 'view-ui-plus/dist/locale/vi-VN';

import "filepond/dist/filepond.min.css";
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';


const app = createApp(App);

app.mixin({
    methods: {
        urlImage(image) {
            if(image.slice(0, 1) == '/'){
                image = image.slice(1);
            }
            return process.env.VUE_APP_BASE_ASSETS + image;
        },

        onlyNumber($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }
        },
    }
})

app.use(ViewUIPlus, {
    locale
});

function loggedIn() {
    return localStorage.getItem('token');
}

router.beforeEach((to, from, next) => {
    ViewUIPlus.LoadingBar.start();
    if (to.matched.some(record => record.meta.login == true)) {
        if (!loggedIn()) {
            ViewUIPlus.Notice.warning({
                title: 'Vui lòng đăng nhập',
                desc: ''
            });
            next({
                path: '/login',
                query: { redirect: to.fullPath }
            });
        } else {
            next()
        }
    } else if(to.matched.some(record => record.meta.login == false)) {
        if(loggedIn()) {
            next({
                path: '/',
                query: { redirect: to.fullPath }
            })
        } else {
            next()
        }
    } else {
        next({
            path: '/',
            query: { redirect: to.fullPath }
        })
    }
})

router.afterEach(route => {
    ViewUIPlus.LoadingBar.finish();
});

require('@/config/axios');

app.use(store)
    .use(router)
    .use(ViewUIPlus)
    .mount('#app')
