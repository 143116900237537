window.axios = require('axios');

let token = localStorage.getItem('token');
axios.defaults.baseURL = process.env.VUE_APP_API_URL + '/api';
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

import { apiGet, apiPost, apiPut, apiDelete } from './api';
window.apiGet = apiGet;
window.apiPost = apiPost;
window.apiPut = apiPut;
window.apiDelete = apiDelete;
